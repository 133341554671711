import '../../../styles/filloutStyles.css'

import loadable from '@loadable/component'

const FilloutPopupEmbed = loadable(() =>
  import('@fillout/react').then((mod) => mod.FilloutPopupEmbed)
)

export const StellaFilloutPopupEmbed = ({
  filloutId,
  onClose,
  parameters,
  domain,
  inheritParameters
}) => {
  return (
    <FilloutPopupEmbed
      filloutId={filloutId}
      onClose={onClose}
      parameters={parameters}
      domain={domain}
      inheritParameters={inheritParameters}
    />
  )
}
