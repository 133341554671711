import { Box, Typography } from '@material-ui/core'

import { useOfferChipsSectionStyles } from './hooks'

import { faGift } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip } from '../../../../../Chip'

export const OfferChipsSection = ({ offers }) => {
  const { classes } = useOfferChipsSectionStyles()

  const renderOfferChips = () => {
    const renderedOfferChips = []

    // if (offers.onlineConsultation) {
    //   renderedOfferChips.push(
    //     <Chip
    //       key="consultation"
    //       label={<Typography className={classes.chipLabel}>Video Consultation</Typography>}
    //       type="consultation"
    //     />
    //   )
    // }

    if (offers.hundredCredit) {
      renderedOfferChips.push(
        <Chip
          key="credit"
          label={
            <Typography className={classes.chipLabel}>
              <FontAwesomeIcon
                icon={faGift}
                style={{ color: 'var(--marketplaceColor)', marginRight: 5 }}
              />
              Accepts Stella Credit
            </Typography>
          }
          type="primary"
        />
      )
    }

    return renderedOfferChips
  }

  if (offers.onlineConsultation || offers.hundredCredit) {
    return <Box className={classes.container}>{renderOfferChips()}</Box>
  }

  return null
}
